import React from "react";
import HeroAlternate from "../components/HeroAlternate";
import ContactForm from "../components/ContactForm";

export default function Contact() {
  return (
    <div>
      <HeroAlternate title="contact" />
      <ContactForm />
    </div>
  );
}
