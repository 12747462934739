import React from "react";
import HeroAlternate from "../components/HeroAlternate";
import resumeImg from "../assets/lucyResume.png";

export default function Resume() {
  return (
    <div>
      <HeroAlternate title="resume" />
      <div className="container primary-background center-text" id="resume">
        <img src={resumeImg} alt="" width="80%" />
      </div>
    </div>
  );
}
