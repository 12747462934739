import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home.jsx";
import Videos from "./pages/Videos.jsx";
import Events from "./pages/Events.jsx";
import Resume from "./pages/Resume.jsx";
import Contact from "./pages/Contact.jsx";
import Navbar from "./components/Navbar.jsx";
import ScrollToTop from "./components/ScrollToTop.js";

export default function App() {
  return (
    <>
      <HashRouter basename="/">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/events" element={<Events />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <ScrollToTop />
      </HashRouter>
    </>
  );
}
