import React from "react";
import eventsSvg from "../assets/events.svg";
import videosSvg from "../assets/videos.svg";
import resumeSvg from "../assets/resume.svg";
import contactSvg from "../assets/contact.svg";

export default function HeroAlternate(props) {
  const eventsImg = eventsSvg;
  const videosImg = videosSvg;
  const resumeImg = resumeSvg;
  const contactImg = contactSvg;

  return (
    <div className="primary-background primary-padding container" id="hero-alt">
      <div className="flexbox-horiz">
        <h1 className="title-top primary-text">{props.title}</h1>
        {
          <img
            className="hero-image-alt"
            src={eval(`${props.title}Img`)}
            alt=""
          />
        }
      </div>
    </div>
  );
}
