const videoData = [
  {
    id: 1,
    title: "Part of Your World",
    titleAlt: "",
    description: "The Little Mermaid",
    youtubeURL: "https://www.youtube.com/embed/yGveayDyB7E?si=pT8VMNeCFDBolKVE",
  },
  {
    id: 2,
    title: "Vocal Highlights",
    titleAlt: "",
    description: "Lucienne Franco",
    youtubeURL: "https://www.youtube.com/embed/hd81mRy906M?si=zjIsgpccc0HFt6aD",
  },
  {
    id: 3,
    title: "Live Music at Cooper Ridge",
    titleAlt: "",
    description: "Lucienne Franco",
    youtubeURL: "https://www.youtube.com/embed/H91dTUvVoFA?si=eWaVYykuoIxT7V9s",
  },
  {
    id: 4,
    title: "Roméo et Juliette",
    titleAlt: "",
    description: "Act I, Je veux vivre",
    youtubeURL: "https://www.youtube.com/embed/su5YZa_3Pn0?si=DPfx_ADPMaj_W9Qn",
  },
  {
    id: 5,
    title: '"On My Own"',
    titleAlt: "",
    description: "Les Misérables",
    youtubeURL: "https://www.youtube.com/embed/9yH3bFhrDQ0?si=7aL13oagIm-EoW60",
  },
  {
    id: 6,
    title: '"All I Ask of You"',
    titleAlt: "Duet with Michael Washington",
    description: "The Phantom of the Opera",
    youtubeURL: "https://www.youtube.com/embed/PAuSeeNfZDU?si=kpAYAIWHBI1i82yj",
  },
  {
    id: 7,
    title: '"Something"',
    titleAlt: "",
    description: "The Beatles",
    youtubeURL: "https://www.youtube.com/embed/cShLUJ-fXpk?si=eb_giOOsrMqD1YwJ",
  },
  {
    id: 8,
    title: '"Think of Me"',
    titleAlt: "",
    description: "The Phantom of the Opera",
    youtubeURL: "https://www.youtube.com/embed/RaSTXPfj4NY?si=TFJpPx-luQH-INTh",
  },
];

export default videoData;
