import React from "react";
import lucienneImg from "../assets/lucienne.jpg";

export default function Hero() {
  return (
    <div className="container primary-background primary-padding" id="hero">
      <div className="flexbox-horiz">
        <div className="flexbox-vert">
          <h1 className="title-top primary-text">Lucienne Franco</h1>
          <h2 className="title-bottom secondary-text">
            Singer - Pianist - Teacher
          </h2>
          <p className="secondary-text">
            Lucienne is a soprano vocalist and keyboardist in the Orange County
            area. Having graduated from Chapman University in Vocal Performance,
            she has been involved with musical theatre, opera, choral, and solo
            work for many years. Lucienne enjoys performing all genres of music
            in a variety of settings, such as assisted living homes including
            Memory Care units, wineries, weddings, and any environment in need
            of entertainment, and she enjoys teaching people of all ages and
            musical backgrounds!
          </p>
        </div>
        <img src={lucienneImg} className="hero-image" alt="" />
      </div>
    </div>
  );
}
