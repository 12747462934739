import React from "react";
import HeroAlternate from "../components/HeroAlternate";
import EventTable from "../components/EventTable";
import { pastEventData, upcomingEventData } from "../data/eventData";

export default function Events() {
  return (
    <div>
      <HeroAlternate title="events" />
      <div id="event-tables">
        <EventTable idx={0} title="Upcoming Events" data={upcomingEventData} />
        {upcomingEventData.length === 0 && (
          <p className="container primary-background center-text no-events">
            There are currently no upcoming events.
          </p>
        )}
        <EventTable idx={1} title="Past Events" data={pastEventData} />
      </div>
    </div>
  );
}
