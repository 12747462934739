import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_i5yn4ar",
        "template_pvsod0s",
        form.current,
        "rweAZ1k2Qc_d_oMxX"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    document.getElementById("user-name").value = "";
    document.getElementById("user-email").value = "";
    document.getElementById("message").value = "";
  };

  return (
    <div className="container secondary-background contact primary-padding">
      <form
        className="contact-form flexbox-vert"
        ref={form}
        onSubmit={sendEmail}
      >
        <label>Name</label>
        <input type="text" name="user_name" id="user-name" required />
        <label>Email</label>
        <input type="email" name="user_email" id="user-email" required />
        <label>Message</label>
        <textarea name="message" rows={3} id="message" required />
        <input type="submit" value="Send" />
      </form>
    </div>
  );
}
